/**
 * index.js
 */

'use strict';

var TB = {};

TB = {

  SECTIONS : ['cover', 'mission', 'news', 'business', 'recruit', 'about', 'contact'],

  PARALLAX : null,

  EVENTS : {
    ANIMATION_END : 'oTransitionEnd mozTransitionEnd webkitTransitionEnd transitionend'
  },

  LAYOUT : 'pc',

  DEVICE : 'pc',

  BREAKPOINT : 768,

  init : function(){
    var $loading = $('#loading');
    TB.DEVICE = TB.checkDevice();
    TB.LAYOUT = TB.checkLayout();
    $(function(){
      var hash = location.hash,
          id = hash ? location.hash.replace(/\//g, '') : null;
      if(hash) location.hash = '#/';
      TB.setDeviceClass();

      //
      // fixed issue #107
      //
      if( TB.DEVICE === 'sp' ) {
        // 全て読みこんでから擬似スクロール実装
        $('.container').css({'height':'100%'});
        $('#sp-scrollable').css({'position':'absolute'});
      } else {
        // SP以外はdivでくくるとバグるので削除
        var dom = $('#sp-scrollable').html();
        $('#sp-scrollable').after(dom);
        $('#sp-scrollable').remove();
      }
      // SPのスクロール時にfooterが描写されない件修正
      // TODO 怖いので他のやり方考えたい
      if ( TB.DEVICE == 'sp' ) {
        $('#sp-scrollable').on('scroll',function() {
          // console.log('onload');
          $('footer .footer-inner').css({'min-height':'1px'});
          setTimeout(function(){$('footer .footer-inner').css({'min-height':'0px'});},100);
        });
      }
      $('.pagetop a').on('click',function(){
        if(TB.DEVICE == 'sp') { $('#sp-scrollable').animate({ scrollTop: 0 }, 'slow'); }
        else { TB.scrollTo('body'); }
        if(TB.DEVICE == 'sp' || TB.DEVICE == 'tablet') $('.container').removeClass('open_menu');
        return false;
      });


      TB.cover.init();
      TB.recruit.init();
      TB.header.resize();
      TB.onlineStore.resize();
      TB.setEvent();
      TB.setDiff();
      TB.setWinHeight(['#news .section-name']);
      TB.contactForm.init();


      // PC版
      if($(window).width() >= TB.BREAKPOINT && TB.DEVICE == 'pc'){
        // プリロード処理
        // TB.loadContents();

        TB.loadContents(function(){

          $loading.addClass('is_start');
          $('body').removeClass('is_loading');


          if(id && $(id).length) $(window).scrollTop($(id).offset().top);

          // 文字のマスクアニメーションが終わった後
          $('.down .mask', $loading).on(TB.EVENTS.ANIMATION_END, function(e){
            e.preventDefault();
            $loading.addClass('is_complete');
            return false;
          });
          // ロード画面の上下分割アニメーションが終わった後
          $('.down', $loading).on(TB.EVENTS.ANIMATION_END, function(){
            TB.cover.movie.play('ANIMATION_END');
            TB.removeLoading();
            // console.log('ok');
          });
        });

      // タブレット版
      } else if(TB.DEVICE == 'tablet') {
        $('meta[name="viewport"]').attr('content', 'width=1280,user-scalable=no');   // viewport 書き換え
        if(id && $(id).length){
          TB.scrollTo(id, true, function(){
            TB.removeLoading();
          });
        } else {
          TB.removeLoading();
        }
        $('section.hidden').removeClass('hidden');
      // スマホ版
      } else {
        if(id && $(id).length){
          TB.scrollTo(id, true, function(){
            TB.removeLoading();
          });
        } else {
          TB.removeLoading();
        }
        $('section.hidden').removeClass('hidden');
      }

    });

  },

  loadContents : function(callback){

    var callback = callback,
        preload = ['loadImages', 'loadMovie', 'setParallax'],
        preload_images = [
          'about_bg.jpg',
          'business_bg.jpg',
          'mission_bg.jpg',
          'contact_bg.jpg',
          'news_bg.jpg',
          'recruit_bg_mask.png',
          'recruit_bg.png'
        ],
        loaded = 0;

    function update(){
      loaded++;
      $('#loading .bar').stop().animate({'width': (loaded/preload.length * 100) + '%'}, 2000, 'easeInOutCirc', function(){
        if(loaded >= preload.length && callback){
          callback();
        }
      });
    }

    // loadImages
    TB.loadImages(preload_images, update);

    // loadMovie
    TB.cover.loadMovie(update);

    // setParallax
    if($(window).width() >= TB.BREAKPOINT){
      TB.setParallax(update);
    } else {
      update();
    }

  },

  loadImages : function(images, callback){
    var images = images,
        callback = callback,
        image_path = 'assets/themes/tokyobase/img/',
        loaded = 0;

    function loader(src){
      var img = new Image();
      if(img.complete){
        complete();
      } else {
        $(img).load(complete);
      }
      img.src = image_path + src;
    }

    function complete(){
      loaded++;
      if(loaded >= images.length && callback){
        callback();
      }
    }

    for(var i=0, len=images.length; i<len; i++){
      loader(images[i]);
    }
  },

  removeLoading : function(){
    $('#loading').remove();
    $('body').removeClass('is_loading');
  },

  setEvent : function(){
    var resizeTimer = null,
        scrollTimer = null;

    // ページ内スクロール
    $('a[href^="#"]').click(function(){
      var target = $(this).attr('href');
      if($(target).length || target == '#/'){
        TB.scrollTo($(this).attr('href'));
        if(TB.DEVICE == 'sp' || TB.DEVICE == 'tablet'){
          $('.container').removeClass('open_menu');
        }
      }
      return false;
    });


    $(window).on({
      'load' : function() {
        // fixed issue #155
        if( TB.DEVICE == 'sp' ) $('.news-entries .news-entry>a .thumb').matchHeight().addClass('is-matchHeight');
      },
      // リサイズ
      'resize' : function(){
        if(TB.LAYOUT != TB.checkLayout()) $(window).scrollTop(0);
        TB.DEVICE = TB.checkDevice();
        TB.LAYOUT = TB.checkLayout();
        TB.header.resize();
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function(){
          clearTimeout(resizeTimer);
          TB.cover.resize();
          // PC LAYOUT
          if(TB.LAYOUT == 'pc'){
            if(TB.DEVICE != 'pc') return false;
            TB.setWinHeight(['#news .section-name']);
            if(TB.PARALLAX){
              TB.setDiff(function(){
                TB.PARALLAX.update(true);
                $(window).scrollTop($(window).scrollTop()+10);
              });
            } else {
              TB.setDiff(TB.setParallax);
            }
          // SP LAYOUT
          } else {
            if($('#loading').length) TB.removeLoading();
            if(TB.PARALLAX){
              TB.PARALLAX.destroy(true);
              TB.PARALLAX = null;
            }
            TB.setDiff();
          }
        }, 800);
      },
      // スクロール時
      'scroll' : function(){
        if(TB.DEVICE == 'pc' && TB.LAYOUT == 'pc'){
          // fixed コンテンツの横スクロール
          $('.fixed-section .section-parallax-container').css('left', -$(window).scrollLeft());
          // pointer-events: none
          $('.container').addClass('disable-pointer-events');
          clearTimeout(scrollTimer);
          scrollTimer = setTimeout(function(){
            clearTimeout(scrollTimer);
            $('.container').removeClass('disable-pointer-events');
          }, 300);

        }
      }
    });

    // タッチデバイス
    $(document).on('touchstart', 'a', function(e){
      if(TB.DEVICE != 'pc') $(this).addClass('touch');
    }).on('touchend focusout mouseleave', 'a', function(e){
      if(TB.DEVICE != 'pc')  $(this).removeClass('touch');
    });
  },

  setWinHeight : function(array){
    for(var i=0, len=array.length; i<len; i++){
      $(array[i]).css({'height':$(window).height()});
    }
  },

  setParallax : function(callback){
    TB.PARALLAX = new ScrollMagic.Controller();
    TB.cover.setParallax();
    TB.mission.setParallax();
    TB.news.setParallax();
    TB.business.setParallax();
    TB.recruit.setParallax();
    TB.about.setParallax();
    TB.contact.setParallax();
    if(callback) callback();
  },

  removeParallax : function(){
    for(var i=0, len=TB.SECTIONS.length; i<len; i++){
      $('#'+TB.SECTIONS[i]).find('*[style]').attr('style', '').end()
        .find('.animate').removeClass('animate');
    }
    TB.PARALLAX = TB.PARALLAX.destroy(true);
    TB.PARALLAX = null;
  },

  initTitle : function(id){
    var canvas, stage, exportRoot;
    canvas = document.getElementById(id + '-title');
    exportRoot = new lib[id]();

    stage = new createjs.Stage(canvas);
    stage.addChild(exportRoot);
    stage.update();

    createjs.Ticker.setFPS(lib.properties.fps);
    createjs.Ticker.addEventListener("tick", stage);
  },

  scrollTo : function(target, no_animation, callback){
    var top = $(target).offset().top,
        once = false,
        speed = no_animation ? 0 : 'slow';
    if($(target).length){
      if(TB.DEVICE == 'sp') top -= 50;
      if(TB.DEVICE == 'tablet') top -= 60;
      if (TB.DEVICE === 'sp') {
        $('#sp-scrollable').stop().animate({scrollTop:top}, speed, function(){
          if(!once){
            once = true;
            if(callback) callback();
          }
        });
      } else {
        $('html,body').stop().animate({scrollTop:top}, speed, function(){
          if(!once){
            once = true;
            if(callback) callback();
          }
        });
      }


    }
  },

  setCurrentMenu : function(id){
    $('#menu a[href="#' + id + '"]').addClass('active').parents('li').siblings().find('a').removeClass('active');
  },

  setDeviceClass : function(){
    if(TB.checkDevice() == 'pc'){
      $('html').addClass('pc');
    } else if(TB.checkDevice() == 'tablet') {
      $('html').addClass('tablet');
    }
  },

  checkDevice : function(){
    var _ua = (function(u){
      return {
        Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1)
          || u.indexOf("ipad") != -1
          || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
          || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
          || u.indexOf("kindle") != -1
          || u.indexOf("silk") != -1
          || u.indexOf("playbook") != -1,
        Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
          || u.indexOf("iphone") != -1
          || u.indexOf("ipod") != -1
          || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
          || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
          || u.indexOf("blackberry") != -1
      }
    })(window.navigator.userAgent.toLowerCase());
    if(_ua.Tablet){
      return 'tablet'
    } else if(_ua.Mobile){
      return 'sp';
    } else {
      return 'pc';
    }
  },

  checkLayout : function(){
    if($(window).width() >= TB.BREAKPOINT){
      return 'pc';
    } else {
      return 'sp';
    }
  },

  getDiff : function(deg){
    return Math.ceil($(window).width()/2 * Math.tan(deg * Math.PI/180));
  },

  setDiff : function(callback){
    var diff = TB.getDiff(15);
    $('section, footer').removeAttr('style').find('[style]').removeAttr('style');
    if(TB.LAYOUT == 'pc'){
      $('.is_skew').each(function(){
        var $this = $(this);
        $('.section-bg-container', $this).css({'margin-top':-diff*1.4});
        $this.find('.section-inner').css({'padding-bottom':diff*1.4});
        if($this.is('#contact') || $this.is('#business')){
          $this.find('.section-bg-container').css({'padding-bottom':diff*1.4});
        } else {
          $this.find('.section-bg-container').css({'padding-bottom':diff*2});
        }
        // inner の中
        $this.find('.section-bg-inner, .section-triangle-inner').css({'margin-top':diff});
      });
      $('#about').find('.section-inner').css({'padding-bottom':diff*2});
      $('footer').css({'height':TB.getDiff(5)*2 + 30, 'margin-top':-(TB.getDiff(5)*2 + 30)});
      $('.footer-bg').css({'margin-top':TB.getDiff(5)});
    } else {
      $('.is_skew').each(function(){
        var $this = $(this);
        $this.find('.section-bg-container').css({'margin-top':-diff, 'padding-bottom':diff});
        $this.find('.section-inner').css({'padding-bottom':diff});
        // inner の中
        $this.find('.section-bg-inner, .section-triangle-inner').css({'margin-top':diff});
      });
    }
    if(callback) callback();
  }
}

TB.init();

TB.contactForm = {

  init : function() {

    var vars = {

      formWrap : $('.top_contact'),
      form : $('.top_contact form'),
      formConfirm : $('.contact-confirm'),
      formBack : $('.contact-back'),
      formSubmit : $('.contact-submit'),
      formSubmitBtn : $('#send'),

      errorMsg_empty : '入力してください',
      errorMsg_emptySelect : '選択してください',
      errorMsg_notCoincide : 'メールアドレスが一致していません',
      errorMsg_notVaridated : 'メールアドレスの形式が不正です',

      m : $('.top_contact .email input'),
      mc : $('.top_contact .email2 input'),
      msgWrap1 : $('.errorMsg_mail1'),
      msgWrap2 : $('.errorMsg_mail2'),

    }

    // エンターで送信を防止する
    $("input",vars.formWrap).on("keydown", function(e) {
        if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
            return false;
        } else {
            return true;
        }
    });

    TB.contactForm._SORTinit();

    vars.msgWrap1.hide();
    vars.msgWrap2.hide();
    vars.formSubmitBtn.hide();

    $('input[type="text"]',vars.formWrap).each( function() {
      TB.contactForm._bindForms($(this),'',vars.errorMsg_empty);
    });
    $('textarea',vars.formWrap).each( function() {
      TB.contactForm._bindForms($(this),'',vars.errorMsg_empty);
    });

    //
    // 『COMFIRM』クリック
    //
    vars.formConfirm.on('click', function() {
      //
      // 入力チェック ( 不備があればエラー表示に )
      //
      var submitFlg = true;
      submitFlg = TB.contactForm._mailVaridCheck(submitFlg,vars);
      if (submitFlg) submitFlg = TB.contactForm._mailCoincideCheck(submitFlg,vars);
      submitFlg = TB.contactForm._mailEmptyCheck(submitFlg,vars);

      if ( submitFlg ) {
        //
        // 入力チェックOKだったら確認画面へ
        //
        $('input[type="text"], textarea',vars.formWrap).each( function() {
          var val = $(this).val();
          $(this).after('<div class="confirm−form">'+val+'</div>');
          vars.formWrap.addClass('confirm');
        });
        $('.pages-sortSelect',vars.formWrap).each( function() {
          var val = $('select option:selected',this).val();
          $(this).after('<div class="confirm−form">'+val+'</div>');
          vars.formWrap.addClass('confirm');
        });
        // コンタクト先頭に戻る
        TB.contactForm._backToContactTop();
      } else {
        // 入力に不備があればフォーム先頭へ戻る
        TB.contactForm._backToFormTop();
      }
      // return false;
    });

    //
    // 『BACK』クリック
    //
    vars.formBack.on('click',function() {
      vars.formWrap.removeClass('confirm');
      $('.confirm−form').remove();
      // フォーム先頭へ戻る
      TB.contactForm._backToFormTop();
    });

    //
    // 『SUBMIT』クリック
    //
    vars.formSubmit.on('click', function() {
      $('.contact-submitWrap').addClass('nowSubmit');
      // setTimeout( function() {
        var timer = null;
        var self = vars.formSubmit;
        // var clicked = false;
        self.removeClass("filled");
        self.addClass("circle");
        self.html("");
        // clicked = true;
        $("svg").css("display", "block");
        $(".circle_2").attr("class", "circle_2 fill_circle");
        timer = setInterval(
          function tick() {
          self.removeClass("circle");
          self.addClass("filled");
          // self.html("b");
          $(".wrap img").css("display", "block");
          $("svg").css("display", "none");
          clearInterval(timer);
        }, 2500);
      // },500);
      setTimeout( function() {
        // vars.form.submit();
        vars.formSubmitBtn.trigger('click');
        // コンタクト先頭に戻る
        TB.contactForm._backToContactTop();
        // フォーム、などなどフェードアウト
        $('.contact-inputWrap, .contact-submitWrap, .contact-required, .textmsg_def, .textmsg_confirm').fadeOut();
      } ,5000);
    });
  },

  // フォームの先頭に戻る
  _backToFormTop : function() {
    if(TB.DEVICE=='sp') {
      var currentScrollTop = $("#sp-scrollable").scrollTop();
      var position = $('#contactForm').position().top + 150;
      $("#sp-scrollable").animate({scrollTop: currentScrollTop - position}, 300, "swing");
    } else {
      var position = $('#contactForm').offset().top - 100;
      $("html, body").animate({scrollTop:position}, 300, "swing");
    }
  },

  // コンタクトコンテンツの先頭に戻る
  _backToContactTop : function() {
    if(TB.DEVICE=='sp') {
      var currentScrollTop = $("#sp-scrollable").scrollTop();
      var position = $('#contact').position().top + 800;
      $("#sp-scrollable").animate({scrollTop: currentScrollTop - position}, 300, "swing");
    } else {
      var position = $('#contact').offset().top - 100;
      $("html, body").animate({scrollTop:position}, 300, "swing");
    }
  },

  _checkVal : function(val,vars) {
    if(!val) return false;
    if(val=='') return false;
    if(val==vars.errorMsg_empty) return false;
    if(val==vars.errorMsg_emptySelect) return false;
    if(val==vars.errorMsg_notCoincide) return false;
    if(val==vars.errorMsg_notVaridated) return false;
    return true;
  },

  // 必須項目入力チェック
  _mailEmptyCheck : function(submitFlg,vars) {
    // 入力チェック selectbox
    vars.formWrap.find('.pages-sortSelect.required').each( function() {
      var val = $(this).find('option:selected').val();
      if ( TB.contactForm._checkVal(val,vars) === false ) {
        $(this).addClass('is-error');
        submitFlg = false;
      }
    });
    // 入力チェック input/textarea
    vars.formWrap.find('input.required, textarea.required').each( function() {
      var val = $(this).val();
      if ( TB.contactForm._checkVal(val,vars) === false ) {
        $(this).val(vars.errorMsg_empty).addClass('is-error');
        submitFlg = false;
      }
    });
    return submitFlg;
  },

  // メールアドレス形式チェック
  _mailVaridCheck : function(submitFlg,vars) {
    var mV = vars.m.val(),
        mCheckV = vars.mc.val();
    if ( TB.contactForm._checkVal(mV,vars) && !mV.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)) {
      submitFlg = false;
      vars.m.addClass('is-error');
      vars.msgWrap1.text(vars.errorMsg_notVaridated).show();
    }
    return submitFlg;
  },

  // メールアドレス一致チェック
  _mailCoincideCheck : function(submitFlg,vars) {
    var mV = vars.m.val(),
        mCheckV = vars.mc.val();
    // 再入力一致チェック
    if ( TB.contactForm._checkVal(mV,vars) && TB.contactForm._checkVal(mCheckV,vars) && mV !== mCheckV) {
      submitFlg = false;
      vars.mc.addClass('is-error');
      vars.msgWrap2.html(vars.errorMsg_notCoincide).show();
    }
    return submitFlg;
  },

  _bindForms : function(form,def,emptyMsg) {
    form
    .val(def)
    .on('focus', function() {
      form.addClass("is-changed").removeClass('is-error');
      var val = form.val();
      if ( val === def || val === emptyMsg ) form.val("");

      if($(this).hasClass('contact-email1')) $('.errorMsg_mail1').hide();
      if($(this).hasClass('contact-email2')) $('.errorMsg_mail2').hide();
    }).on('blur', function() {
      var val = form.val();
      if( val === "" ) form.val(def).removeClass("is-changed");
    });
  },

  _SORTinit : function() {
    if ($('.pages-sortSelect').length) {
      $('.pages-sortSelect').each( function() {
        var _self = $(this);
        $('select',_self).bind('change', function() {
          TB.contactForm.SORTdisplay($('.pages-sortSelect-display',_self),$('option:selected',_self).html());
          $('.pages-sortSelect select option[value="選択してください"]').remove();
          _self.addClass('is-change').removeClass('is-error');
        });
        TB.contactForm.SORTdisplay($('.pages-sortSelect-display',_self),$('option:selected',_self).html());
      });
    }

  },

  SORTdisplay : function(disp,val){
    disp.html(val);
  }

}


/**
 * header.js
 */

TB.header = {

  resize : function(){
    $('.container').removeClass('open_menu');
    if(TB.DEVICE == 'pc'){
      $('#menu-btn').off();
      $('#menu').off().hover(
        function(){
          $('.container').addClass('open_menu');
        },
        function(){
          $('.container').removeClass('open_menu');
        }
      );
    } else {
      $('#menu').off();
      $('#menu-btn').off().click(function(){
        $('.container').toggleClass('open_menu');
        return false;
      });
      
      $('.onlineStore_btn').click(function(){
        if($('.container').hasClass('open_menu')){
          $('.container').toggleClass('open_menu');
          return false;
        }
      });
    }
  }
}

TB.Movie = function(elem, callback){
  this.elem = elem;
  this.video = null;
  this.isSupport = true;
  this.init(callback);
}

TB.Movie.prototype = {

  init : function(callback){
    var self = this,
        $this = $(self.elem),
        $video = null,
        loaded = false,
        timeout = 10000,
        timer = null,
        callback = callback;

    function success(){
      if(loaded) return false;
      loaded = true;
      self.stop();
      self.setEvent();
      callback();
    }

    function error(e){
      if(loaded) return false;
      loaded = true;
      self.generate(false);
      callback();
    }

    self.generate(TB.DEVICE=='pc'&&TB.LAYOUT=='pc', function(){
      $video = $('video', $this);
      // console.log('readyState : ' + $video.get(0).readyState);
      self.stop();
      try{
        $video.get(0).oncanplay = function(){
          success();
        }
        $video.get(0).onerror = function(){
          error();
        }
        if($video.get(0).readyState >= 3) {
          success();
        }
      } catch(e){
        error(e);
      }

      timer = setTimeout(function(){
        clearTimeout(timer);
        error('timeout');
      }, timeout);
    });
  },

  generate : function(is_pc, callback){
    $('#movie').html($('#tmpl-movie').tmpl({is_pc:is_pc}));
    if(callback) callback();
  },

  setEvent : function(){
    var self = this,
        $this = $(self.elem),
        $video = $('video', $this),
        $window = $(window);

    if(!self.isSupport) return false;
  },

  stop : function(){
    var self = this,
        $this = $(self.elem),
        $video = $('video', $this);
    if(!self.isSupport) return false;
    if($video.length) $video.addClass('stop').get(0).pause();
  },

  play : function(log){
    var self = this,
        $this = $(self.elem),
        $video = $('video', $this),
        log = log ? log : null;
    // console.log('play : from ' + log);
    if(!self.isSupport) return false;
    if($video.length) $video.removeClass('stop').get(0).play();
  }
}

/**
 * onlineStore.js
 */

TB.onlineStore = {

  resize : function(){

    if(TB.DEVICE == 'pc'){
      //pc 
      $('.wrap_onlineStore').on({
        'mouseenter':function() {
          $('.wrap_onlineStore').addClass('is-open');
          $(".onlineStore_content").stop().animate({
            opacity: '1'
          },300,function(){
            $(".onlineStore_content li")
              .each(function(i){
              $(this).delay(300 * i).animate({opacity:1}, 300);
            });
          });
        },
        'mouseleave':function() {
          $(".wrap_onlineStore").removeClass('is-open');
          $(".onlineStore_content,.onlineStore_content li").stop().animate({
            opacity: "0"
          },100);
        }
      });
    } else {
      //sp
      var spFlag = false;
      $('.onlineStore_btn').on('click',function(){
        $('.wrap_onlineStore').toggleClass('is-open');

        if(!spFlag){
          //open
          $('.onlineStore_btn img').attr("src","/assets/themes/tokyobase/img/icon_close_k.svg");
          $(".onlineStore_content").stop().animate({
            opacity: '1'
          },300,function(){
            $(".onlineStore_content li")
              .each(function(i){
              $(this).delay(300 * i).animate({opacity:1}, 300);
            });
          });
          spFlag = true;

        }else{
          //close
          $('.onlineStore_btn img').fadeOut('', function() { 
            $('.onlineStore_btn img').attr("src","/assets/themes/tokyobase/img/icon_cart_k.svg").fadeIn();
          ;});
          $(".onlineStore_content,.onlineStore_content li").stop().animate({
            opacity: "0"
          },100);
          spFlag = false;
        }
      });
      
      $('#menu-btn').on('click',function(){
        if(spFlag){
          $('.wrap_onlineStore').toggleClass('is-open');
          $('.onlineStore_btn img').fadeOut('', function() { 
            $('.onlineStore_btn img').attr("src","/assets/themes/tokyobase/img/icon_cart_k.svg").fadeIn();
            ;});
          $(".onlineStore_content,.onlineStore_content li").stop().animate({
            opacity: "0"
          },100);
          spFlag = false;
        }
      });
    }
  }
}

;(function (lib, img, cjs) {

var p; // shortcut to reference prototypes

// library properties:
lib.properties = {
	width: 850,
	height: 200,
	fps: 15,
	color: "#FFFFFF",
	manifest: []
};



// symbols:



(lib.YOKO = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AjRAxIAAhhIGjAAIAABhg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21,-4.9,42.1,9.9);


(lib.U = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AikDkQhAgzAAhfIAAlqICBAAIAAFqQAAAzAbAYQAaAZAvgBQAvAAAagYQAagZAAgyIAAlqICBAAIAAFqQAABfg/AzQhAA1hlAAQhmAAg/g1g");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22.9,-28.1,45.8,56.3);


(lib.T = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("Ag/EVIAAnGIiSAAIAAhjIGjAAIAABjIiSAAIAAHGg");
	this.shape.setTransform(0,-1);

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21,-28.7,42.1,55.5);


(lib.S = function() {
	this.initialize();

	// NEWS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AiXDuQhFgwAChZIABgCIB9AAQAAAwAaAUQAZAVA0AAQAoAAATgQQAUgQAAgaQAAgdgUgQQgUgRgxgRQhoghgygkQgzgnAAhHQAAhGA9gqQA8gsBbAAQBcAAA7AwQA7AwgCBKIgBACIh9AAQAAghgWgVQgXgUgnAAQgmAAgVARQgWARAAAZQAAAYAWAPQAYAQA6AVQBeAcAwAnQAwAqAABHQAABKg5ApQg5ApheAAQhdAAhFgvg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21.9,-28.5,43.8,57.1);


(lib.O = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AipDbQhBhDAAhmIAAhiQAAhmBBhDQBChDBnAAQBoAABCBDQBBBDAABmIAABiQAABlhBBEQhBBChpAAQhnAAhChCgAhOiTQgcAmAAA8IAABjQAAA8AcAmQAcAmAyAAQAzAAAcgmQAcgmAAg8IAAhjQAAg7gcgmQgcgngzAAQgyAAgcAmg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.5,-28.5,47.2,57.1);


(lib.B = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AjVEVIAAopIC/AAQBlAAA6AnQA6AmAABLQAAApgTAeQgTAfgmAPQAxAKAXAhQAXAgAAAtQAABRg3ApQg2AqhmAAgAhTCxIBWAAQAqABAUgRQAVgPAAgiQAAgkgSgTQgSgSgnAAIheAAgAhTgtIBAAAQApAAAWgRQAWgQAAgfQAAgjgWgRQgWgPgsAAIg9AAg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21.4,-27.7,42.8,55.5);


(lib.A = function() {
	this.initialize();

	// ABOUT US
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AB2EVIgfhsIitAAIgfBsIiCAAICzopICIAAIC0IpgAg5BGIBzAAIg6jFIAAAAg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-24.8,-27.7,49.7,55.5);


(lib.stripe = function() {
	this.initialize();

	// レイヤー 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape.setTransform(132.6,132.6,1.973,0.205,-45);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_1.setTransform(114.9,114.9,1.973,0.205,-45);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_2.setTransform(97.2,97.3,1.973,0.205,-45);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_3.setTransform(79.5,79.6,1.973,0.205,-45);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_4.setTransform(61.9,61.9,1.973,0.205,-45);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_5.setTransform(44.2,44.2,1.973,0.205,-45);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_6.setTransform(26.5,26.6,1.973,0.205,-45);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#000000").s().p("Eg4DA2aMBudhudIBqBqMhudBudg");
	this.shape_7.setTransform(8.8,8.9);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_8.setTransform(-8.9,-8.8,1.973,0.205,-45);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_9.setTransform(-26.5,-26.5,1.973,0.205,-45);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_10.setTransform(-44.2,-44.2,1.973,0.205,-45);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_11.setTransform(-61.9,-61.8,1.973,0.205,-45);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_12.setTransform(-79.6,-79.5,1.973,0.205,-45);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_13.setTransform(-97.2,-97.2,1.973,0.205,-45);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_14.setTransform(-114.9,-114.9,1.973,0.205,-45);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_15.setTransform(-132.6,-132.5,1.973,0.205,-45);

	this.addChild(this.shape_15,this.shape_14,this.shape_13,this.shape_12,this.shape_11,this.shape_10,this.shape_9,this.shape_8,this.shape_7,this.shape_6,this.shape_5,this.shape_4,this.shape_3,this.shape_2,this.shape_1,this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-491.4,-491.4,982.9,982.9);


(lib.A2 = function() {
	this.initialize();

	// ABOUT US (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AAVEVIiropIB8AAICxIpg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-15.1,-27.7,30.3,55.5);


(lib.A_1 = function() {
	this.initialize();

	// ABOUT US (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AiWEVICxopIB8AAIirIpg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-15.1,-27.7,30.3,55.5);


// stage content:
(lib.about = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// S
	this.instance = new lib.S();
	this.instance.setTransform(434.7,100,14.519,0.13,0,0,0,0.1,0);

	this.timeline.addTween(cjs.Tween.get(this.instance).to({regX:0,scaleX:1,scaleY:1,x:738},4,cjs.Ease.get(1)).wait(1).to({x:730},0).wait(5));

	// U
	this.instance_1 = new lib.U();
	this.instance_1.setTransform(641.1,100.5);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(3).to({_off:false},0).wait(7));

	// T
	this.instance_2 = new lib.T();
	this.instance_2.setTransform(494.8,100.1,1,1,0,0,0,0,-1);
	this.instance_2._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(2).to({_off:false},0).wait(8));

	// U
	this.instance_3 = new lib.U();
	this.instance_3.setTransform(407.6,100.5);
	this.instance_3._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(2).to({_off:false},0).wait(8));

	// O
	this.instance_4 = new lib.O();
	this.instance_4.setTransform(316.2,140);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(3).to({_off:false},0).to({y:100},2,cjs.Ease.get(1)).wait(5));

	// B
	this.instance_5 = new lib.B();
	this.instance_5.setTransform(227.6,64);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(5).to({_off:false},0).to({y:100},1,cjs.Ease.get(1)).wait(4));

	// Acenter
	this.instance_6 = new lib.YOKO();
	this.instance_6.setTransform(167.7,112,0.804,1);
	this.instance_6._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(6).to({_off:false},0).to({scaleX:0.68,x:137.4},1).to({_off:true},2).wait(1));

	// Aright
	this.instance_7 = new lib.A2();
	this.instance_7.setTransform(165.7,154);
	this.instance_7._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(3).to({_off:false},0).to({x:148.3,y:100},2,cjs.Ease.get(1)).wait(3).to({_off:true},1).wait(1));

	// Aleft
	this.instance_8 = new lib.A_1();
	this.instance_8.setTransform(133.2,86.6);
	this.instance_8._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(6).to({_off:false},0).to({x:128.8,y:100},1,cjs.Ease.get(1)).wait(1).to({_off:true},1).wait(1));

	// A
	this.instance_9 = new lib.A();
	this.instance_9.setTransform(138.6,100);
	this.instance_9._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(8).to({_off:false},0).wait(2));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(540.3,196.3,636.7,7.4);

})(lib = lib||{}, images = images||{}, createjs = createjs||{});
var lib, images, createjs;
;(function (lib, img, cjs) {

var p; // shortcut to reference prototypes

// library properties:
lib.properties = {
	width: 850,
	height: 300,
	fps: 15,
	color: "#FFFFFF",
	manifest: []
};



// symbols:



(lib.NANAME = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABmEVIlBopIB2AAIFBIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22,-27.7,44.1,55.5);


(lib.U = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AikDkQhAgzAAhfIAAlqICBAAIAAFqQAAAzAbAYQAaAZAvgBQAvAAAagYQAagZAAgyIAAlqICBAAIAAFqQAABfg/AzQhAA1hlAAQhmAAg/g1g");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22.9,-28.1,45.8,56.3);


(lib.S = function() {
	this.initialize();

	// NEWS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AiXDuQhFgwAChZIABgCIB9AAQAAAwAaAUQAZAVA0AAQAoAAATgQQAUgQAAgaQAAgdgUgQQgUgRgxgRQhoghgygkQgzgnAAhHQAAhGA9gqQA8gsBbAAQBcAAA7AwQA7AwgCBKIgBACIh9AAQAAghgWgVQgXgUgnAAQgmAAgVARQgWARAAAZQAAAYAWAPQAYAQA6AVQBeAcAwAnQAwAqAABHQAABKg5ApQg5ApheAAQhdAAhFgvg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21.9,-28.5,43.8,57.1);


(lib.R = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABgEVQgQgNgEgdQgFghAAgXIAAghQAAgogVgVQgVgVgmAAIhZAAIAADVIiBAAIAAopIDVAAQBfAAA4AtQA4AsAABNQAAAsgWAeQgYAggrARQAzAOAWAiQAWAiAAAyIAAAjQAAAXAHAbQAHAcAPALIAAAIgAhighIBVAAQAmAAATgRQAVgSAAghQAAgigVgVQgVgVglAAIhUAAg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22.8,-27.7,45.8,55.5);


(lib.O = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AipDbQhBhDAAhmIAAhiQAAhmBBhDQBChDBnAAQBoAABCBDQBBBDAABmIAABiQAABlhBBEQhBBChpAAQhnAAhChCgAhOiTQgcAmAAA8IAABjQAAA8AcAmQAcAmAyAAQAzAAAcgmQAcgmAAg8IAAhjQAAg7gcgmQgcgngzAAQgyAAgcAmg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.5,-28.5,47.2,57.1);


(lib.N = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABmEVIjJlfIgCAAIAAFfIiBAAIAAopICBAAIDJFgIACgBIAAlfICBAAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.1,-27.7,46.3,55.5);


(lib.I = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("Ag/EVIAAopIB+AAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.8,55.5);


(lib.E = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AjAEVIAAopIGBAAIAABjIkAAAIAAB4IDYAAIAABhIjYAAIAACJID/AAIAABkg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-19.3,-27.7,38.6,55.5);


(lib.B = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AjVEVIAAopIC/AAQBlAAA6AnQA6AmAABLQAAApgTAeQgTAfgmAPQAxAKAXAhQAXAgAAAtQAABRg3ApQg2AqhmAAgAhTCxIBWAAQAqABAUgRQAVgPAAgiQAAgkgSgTQgSgSgnAAIheAAgAhTgtIBAAAQApAAAWgRQAWgQAAgfQAAgjgWgRQgWgPgsAAIg9AAg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21.4,-27.7,42.8,55.5);


(lib.stripe = function() {
	this.initialize();

	// レイヤー 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape.setTransform(132.6,132.6,1.973,0.205,-45);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_1.setTransform(114.9,114.9,1.973,0.205,-45);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_2.setTransform(97.2,97.3,1.973,0.205,-45);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_3.setTransform(79.5,79.6,1.973,0.205,-45);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_4.setTransform(61.9,61.9,1.973,0.205,-45);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_5.setTransform(44.2,44.2,1.973,0.205,-45);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_6.setTransform(26.5,26.6,1.973,0.205,-45);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#000000").s().p("Eg4DA2aMBudhudIBqBqMhudBudg");
	this.shape_7.setTransform(8.8,8.9);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_8.setTransform(-8.9,-8.8,1.973,0.205,-45);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_9.setTransform(-26.5,-26.5,1.973,0.205,-45);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_10.setTransform(-44.2,-44.2,1.973,0.205,-45);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_11.setTransform(-61.9,-61.8,1.973,0.205,-45);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_12.setTransform(-79.6,-79.5,1.973,0.205,-45);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_13.setTransform(-97.2,-97.2,1.973,0.205,-45);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_14.setTransform(-114.9,-114.9,1.973,0.205,-45);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_15.setTransform(-132.6,-132.5,1.973,0.205,-45);

	this.addChild(this.shape_15,this.shape_14,this.shape_13,this.shape_12,this.shape_11,this.shape_10,this.shape_9,this.shape_8,this.shape_7,this.shape_6,this.shape_5,this.shape_4,this.shape_3,this.shape_2,this.shape_1,this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-491.4,-491.3,982.9,982.8);


(lib.TATE = function() {
	this.initialize();

	// CONCEPT (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ag/EVIAAopIB/AAIAAIpg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.9,55.5);


// stage content:
(lib.business = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// S
	this.instance = new lib.S();
	this.instance.setTransform(741,205.3,1.766,1);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(8).to({_off:false},0).wait(1).to({scaleX:1,x:724.2},0).wait(2));

	// S
	this.instance_1 = new lib.S();
	this.instance_1.setTransform(637,109,1,1.361);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(6).to({_off:false},0).to({scaleY:1,y:205.3},2,cjs.Ease.get(1)).wait(3));

	// E
	this.instance_2 = new lib.E();
	this.instance_2.setTransform(553.5,205.4);
	this.instance_2._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(5).to({_off:false},0).wait(6));

	// right
	this.instance_3 = new lib.TATE();
	this.instance_3.setTransform(480.8,155.4);
	this.instance_3._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(4).to({_off:false},0).to({y:205.4},2,cjs.Ease.get(1)).to({_off:true},1).wait(4));

	// center
	this.instance_4 = new lib.NANAME();
	this.instance_4.setTransform(471,217.4);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(6).to({_off:false},0).to({_off:true},1).wait(4));

	// left
	this.instance_5 = new lib.TATE();
	this.instance_5.setTransform(447.4,265.4);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(4).to({_off:false},0).to({y:195.4},2,cjs.Ease.get(1)).to({_off:true},1).wait(4));

	// N
	this.instance_6 = new lib.N();
	this.instance_6.setTransform(464.1,205.4);
	this.instance_6._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(7).to({_off:false},0).wait(4));

	// I
	this.instance_7 = new lib.I();
	this.instance_7.setTransform(387.5,205.4);
	this.instance_7._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(4).to({_off:false},0).wait(7));

	// S
	this.instance_8 = new lib.S();
	this.instance_8.setTransform(313.8,205.3);
	this.instance_8._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(4).to({_off:false},0).wait(7));

	// U
	this.instance_9 = new lib.U();
	this.instance_9.setTransform(224.9,205.8);
	this.instance_9._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(5).to({_off:false},0).wait(6));

	// B
	this.instance_10 = new lib.B();
	this.instance_10.setTransform(491.4,205.4,14.068,0.164);
	this.instance_10._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_10).wait(3).to({_off:false},0).to({scaleX:1,scaleY:1,x:136.4},2,cjs.Ease.get(1)).wait(6));

	// R
	this.instance_11 = new lib.R();
	this.instance_11.setTransform(321,100.1);
	this.instance_11._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_11).wait(2).to({_off:false},0).wait(9));

	// U
	this.instance_12 = new lib.U();
	this.instance_12.setTransform(228.7,100.5);
	this.instance_12._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_12).wait(3).to({_off:false},0).wait(8));

	// O
	this.instance_13 = new lib.O();
	this.instance_13.setTransform(378.2,100.1,11.331,0.158,0,0,0,-0.2,0);

	this.timeline.addTween(cjs.Tween.get(this.instance_13).to({regX:0,scaleX:1,scaleY:1,x:137.3},4,cjs.Ease.get(1)).wait(7));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(538.6,245.6,534.3,9);

})(lib = lib||{}, images = images||{}, createjs = createjs||{});
var lib, images, createjs;
;(function (lib, img, cjs) {

var p; // shortcut to reference prototypes

// library properties:
lib.properties = {
	width: 800,
	height: 200,
	fps: 15,
	color: "#FFFFFF",
	manifest: []
};



// symbols:



(lib.T = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("Ag/EVIAAnGIiSAAIAAhjIGjAAIAABjIiSAAIAAHGg");
	this.shape.setTransform(0,-1);

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21,-28.7,42.1,55.5);


(lib.O = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AipDbQhBhDAAhmIAAhiQAAhmBBhDQBChDBnAAQBoAABCBDQBBBDAABmIAABiQAABlhBBEQhBBChpAAQhnAAhChCgAhOiTQgcAmAAA8IAABjQAAA8AcAmQAcAmAyAAQAzAAAcgmQAcgmAAg8IAAhjQAAg7gcgmQgcgngzAAQgyAAgcAmg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.5,-28.5,47.2,57.1);


(lib.N = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABmEVIjJlfIgCAAIAAFfIiBAAIAAopICBAAIDJFgIACgBIAAlfICBAAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.1,-27.7,46.3,55.5);


(lib.C = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AidDeQhBg/AAhlIAAhyQAAhkA+hAQA/hABhAAQBoAAA4A1QA4A2AHBhIgBACIh9AAQgDg2gVgaQgWgbgzAAQgqAAgaAkQgZAjAAA5IAABzQAAA5AcAkQAbAjAuAAQAuAAAUgZQATgYADg3IB9AAIABADQgDBhg3A0Qg2AzhmAAQhlAAhAg/g");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22.3,-28.5,44.7,57.1);


(lib.A = function() {
	this.initialize();

	// ABOUT US
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AB2EVIgfhsIitAAIgfBsIiCAAICzopICIAAIC0IpgAg5BGIBzAAIg6jFIAAAAg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-24.8,-27.7,49.7,55.5);


(lib.stripe = function() {
	this.initialize();

	// レイヤー 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape.setTransform(132.6,132.6,1.973,0.205,-45);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_1.setTransform(114.9,114.9,1.973,0.205,-45);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_2.setTransform(97.2,97.3,1.973,0.205,-45);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_3.setTransform(79.5,79.6,1.973,0.205,-45);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_4.setTransform(61.9,61.9,1.973,0.205,-45);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_5.setTransform(44.2,44.2,1.973,0.205,-45);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_6.setTransform(26.5,26.6,1.973,0.205,-45);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#000000").s().p("Eg4DA2aMBudhudIBqBqMhudBudg");
	this.shape_7.setTransform(8.8,8.9);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_8.setTransform(-8.9,-8.8,1.973,0.205,-45);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_9.setTransform(-26.5,-26.5,1.973,0.205,-45);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_10.setTransform(-44.2,-44.2,1.973,0.205,-45);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_11.setTransform(-61.9,-61.8,1.973,0.205,-45);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_12.setTransform(-79.6,-79.5,1.973,0.205,-45);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_13.setTransform(-97.2,-97.2,1.973,0.205,-45);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_14.setTransform(-114.9,-114.9,1.973,0.205,-45);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_15.setTransform(-132.6,-132.5,1.973,0.205,-45);

	this.addChild(this.shape_15,this.shape_14,this.shape_13,this.shape_12,this.shape_11,this.shape_10,this.shape_9,this.shape_8,this.shape_7,this.shape_6,this.shape_5,this.shape_4,this.shape_3,this.shape_2,this.shape_1,this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-491.4,-491.4,982.9,982.9);


(lib.yokostripe01 = function() {
	this.initialize();

	// T (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AjRAxIAAhhIGjAAIAABhg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21,-4.9,42.1,9.9);


(lib.tatestripe02 = function() {
	this.initialize();

	// T (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ag/EVIAAopIB/AAIAAIpg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.9,55.5);


(lib.tatestripe01 = function() {
	this.initialize();

	// T (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ag/JLIAAyVIB/AAIAASVg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-58.7,12.9,117.4);


// stage content:
(lib.contact = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// T
	this.instance = new lib.yokostripe01();
	this.instance.setTransform(743.7,77.2,1,1,0,0,0,-0.1,0);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(6).to({_off:false},0).to({x:645.7},2,cjs.Ease.get(1)).to({_off:true},1).wait(1));

	// T
	this.instance_1 = new lib.tatestripe01();
	this.instance_1.setTransform(655.8,92);

	this.instance_2 = new lib.tatestripe02();
	this.instance_2.setTransform(655.9,100);

	this.instance_3 = new lib.T();
	this.instance_3.setTransform(655.9,101);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.instance_1}]},5).to({state:[{t:this.instance_2}]},1).to({state:[{t:this.instance_3}]},3).wait(1));

	// C
	this.instance_4 = new lib.C();
	this.instance_4.setTransform(571,40);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(5).to({_off:false},0).to({y:100},2,cjs.Ease.get(1)).wait(3));

	// A
	this.instance_5 = new lib.A();
	this.instance_5.setTransform(484.5,150);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(4).to({_off:false},0).to({y:100},1,cjs.Ease.get(1)).wait(5));

	// T
	this.instance_6 = new lib.T();
	this.instance_6.setTransform(411,100.1,1,1,0,0,0,0,-1);
	this.instance_6._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(3).to({_off:false},0).wait(1).to({x:405},0).wait(6));

	// N
	this.instance_7 = new lib.N();
	this.instance_7.setTransform(317.4,100.1);
	this.instance_7._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(2).to({_off:false},0).wait(8));

	// O
	this.instance_8 = new lib.O();
	this.instance_8.setTransform(225.4,100);
	this.instance_8._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(3).to({_off:false},0).wait(7));

	// C
	this.instance_9 = new lib.C();
	this.instance_9.setTransform(380.2,100,12.001,0.074,0,0,0,-0.1,0);

	this.timeline.addTween(cjs.Tween.get(this.instance_9).to({regX:0,scaleX:1,scaleY:1,x:128.1},4,cjs.Ease.get(1)).wait(1).to({x:136.1},0).wait(5));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(513.8,197.9,535.8,4.2);

})(lib = lib||{}, images = images||{}, createjs = createjs||{});
var lib, images, createjs;
;(function (lib, img, cjs) {

var p; // shortcut to reference prototypes

// library properties:
lib.properties = {
	width: 800,
	height: 200,
	fps: 15,
	color: "#FFFFFF",
	manifest: []
};



// symbols:



(lib.NANAME = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABmEVIlBopIB2AAIFBIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22,-27.7,44.1,55.5);


(lib.S = function() {
	this.initialize();

	// NEWS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AiXDuQhFgwAChZIABgCIB9AAQAAAwAaAUQAZAVA0AAQAoAAATgQQAUgQAAgaQAAgdgUgQQgUgRgxgRQhoghgygkQgzgnAAhHQAAhGA9gqQA8gsBbAAQBcAAA7AwQA7AwgCBKIgBACIh9AAQAAghgWgVQgXgUgnAAQgmAAgVARQgWARAAAZQAAAYAWAPQAYAQA6AVQBeAcAwAnQAwAqAABHQAABKg5ApQg5ApheAAQhdAAhFgvg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21.9,-28.5,43.8,57.1);


(lib.O = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AipDbQhBhDAAhmIAAhiQAAhmBBhDQBChDBnAAQBoAABCBDQBBBDAABmIAABiQAABlhBBEQhBBChpAAQhnAAhChCgAhOiTQgcAmAAA8IAABjQAAA8AcAmQAcAmAyAAQAzAAAcgmQAcgmAAg8IAAhjQAAg7gcgmQgcgngzAAQgyAAgcAmg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.5,-28.5,47.2,57.1);


(lib.N = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABmEVIjJlfIgCAAIAAFfIiBAAIAAopICBAAIDJFgIACgBIAAlfICBAAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.1,-27.7,46.3,55.5);


(lib.M = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AClEVIAAiBIANkIIiGGJIhWAAIiHmJIANEIIAACBIiGAAIAAopICvAAIB7GAIB7mAICwAAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-29.9,-27.7,59.9,55.5);


(lib.I = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("Ag/EVIAAopIB+AAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.8,55.5);


(lib.stripe = function() {
	this.initialize();

	// レイヤー 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape.setTransform(132.6,132.6,1.973,0.205,-45);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_1.setTransform(114.9,114.9,1.973,0.205,-45);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_2.setTransform(97.2,97.3,1.973,0.205,-45);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_3.setTransform(79.5,79.6,1.973,0.205,-45);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_4.setTransform(61.9,61.9,1.973,0.205,-45);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_5.setTransform(44.2,44.2,1.973,0.205,-45);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_6.setTransform(26.5,26.6,1.973,0.205,-45);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#000000").s().p("Eg4DA2aMBudhudIBqBqMhudBudg");
	this.shape_7.setTransform(8.8,8.9);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_8.setTransform(-8.9,-8.8,1.973,0.205,-45);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_9.setTransform(-26.5,-26.5,1.973,0.205,-45);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_10.setTransform(-44.2,-44.2,1.973,0.205,-45);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_11.setTransform(-61.9,-61.8,1.973,0.205,-45);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_12.setTransform(-79.6,-79.5,1.973,0.205,-45);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_13.setTransform(-97.2,-97.2,1.973,0.205,-45);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_14.setTransform(-114.9,-114.9,1.973,0.205,-45);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_15.setTransform(-132.6,-132.5,1.973,0.205,-45);

	this.addChild(this.shape_15,this.shape_14,this.shape_13,this.shape_12,this.shape_11,this.shape_10,this.shape_9,this.shape_8,this.shape_7,this.shape_6,this.shape_5,this.shape_4,this.shape_3,this.shape_2,this.shape_1,this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-491.4,-491.3,982.9,982.8);


(lib.TATE = function() {
	this.initialize();

	// CONCEPT (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ag/EVIAAopIB/AAIAAIpg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.9,55.5);


// stage content:
(lib.mission = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// right
	this.instance = new lib.TATE();
	this.instance.setTransform(648.2,50);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(6).to({_off:false},0).to({y:100},2,cjs.Ease.get(1)).to({_off:true},1).wait(1));

	// center
	this.instance_1 = new lib.NANAME();
	this.instance_1.setTransform(638.4,112);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(8).to({_off:false},0).to({_off:true},1).wait(1));

	// left
	this.instance_2 = new lib.TATE();
	this.instance_2.setTransform(614.8,160);
	this.instance_2._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(6).to({_off:false},0).to({y:90},2,cjs.Ease.get(1)).to({_off:true},1).wait(1));

	// N
	this.instance_3 = new lib.N();
	this.instance_3.setTransform(631.5,100);
	this.instance_3._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(9).to({_off:false},0).wait(1));

	// O
	this.instance_4 = new lib.O();
	this.instance_4.setTransform(538.9,60);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(4).to({_off:false},0).to({y:100},2,cjs.Ease.get(1)).wait(4));

	// I
	this.instance_5 = new lib.I();
	this.instance_5.setTransform(462.4,142);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(3).to({_off:false},0).to({y:100},2,cjs.Ease.get(1)).wait(5));

	// S
	this.instance_6 = new lib.S();
	this.instance_6.setTransform(388.2,100);
	this.instance_6._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(2).to({_off:false},0).wait(8));

	// S
	this.instance_7 = new lib.S();
	this.instance_7.setTransform(301.5,100);
	this.instance_7._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(2).to({_off:false},0).wait(8));

	// I
	this.instance_8 = new lib.I();
	this.instance_8.setTransform(227.9,100);
	this.instance_8._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(3).to({_off:false},0).wait(7));

	// M
	this.instance_9 = new lib.M();
	this.instance_9.setTransform(377.7,100,8.813,0.075);

	this.timeline.addTween(cjs.Tween.get(this.instance_9).to({scaleX:1,scaleY:1,x:135.7},4,cjs.Ease.get(1)).wait(1).to({x:143.7},0).wait(5));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(513.8,197.9,527.9,4.2);

})(lib = lib||{}, images = images||{}, createjs = createjs||{});
var lib, images, createjs;
;(function (lib, img, cjs) {

var p; // shortcut to reference prototypes

// library properties:
lib.properties = {
	width: 530,
	height: 200,
	fps: 15,
	color: "#FFFFFF",
	manifest: []
};



// symbols:



(lib.NANAME = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABmEVIlBopIB2AAIFBIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22,-27.7,44.1,55.5);


(lib.W = function() {
	this.initialize();

	// NEWS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABeEVIhdlVIgBAAIhcFVIh5AAIiAopIB9AAIBJFqIACAAIBilqIBXAAIBjFqIACAAIBJlqIB8AAIh/Ipg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-34.2,-27.7,68.6,55.5);


(lib.S = function() {
	this.initialize();

	// NEWS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AiXDuQhFgwAChZIABgCIB9AAQAAAwAaAUQAZAVA0AAQAoAAATgQQAUgQAAgaQAAgdgUgQQgUgRgxgRQhoghgygkQgzgnAAhHQAAhGA9gqQA8gsBbAAQBcAAA7AwQA7AwgCBKIgBACIh9AAQAAghgWgVQgXgUgnAAQgmAAgVARQgWARAAAZQAAAYAWAPQAYAQA6AVQBeAcAwAnQAwAqAABHQAABKg5ApQg5ApheAAQhdAAhFgvg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21.9,-28.5,43.8,57.1);


(lib.N = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABmEVIjJlfIgCAAIAAFfIiBAAIAAopICBAAIDJFgIACgBIAAlfICBAAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-23.1,-27.7,46.3,55.5);


(lib.E = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AjAEVIAAopIGBAAIAABjIkAAAIAAB4IDYAAIAABhIjYAAIAACJID/AAIAABkg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-19.3,-27.7,38.6,55.5);


(lib.stripe = function() {
	this.initialize();

	// レイヤー 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape.setTransform(132.6,132.6,1.973,0.205,-45);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_1.setTransform(114.9,114.9,1.973,0.205,-45);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_2.setTransform(97.2,97.3,1.973,0.205,-45);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_3.setTransform(79.5,79.6,1.973,0.205,-45);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_4.setTransform(61.9,61.9,1.973,0.205,-45);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_5.setTransform(44.2,44.2,1.973,0.205,-45);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_6.setTransform(26.5,26.6,1.973,0.205,-45);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#000000").s().p("Eg4DA2aMBudhudIBqBqMhudBudg");
	this.shape_7.setTransform(8.8,8.9);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_8.setTransform(-8.9,-8.8,1.973,0.205,-45);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_9.setTransform(-26.5,-26.5,1.973,0.205,-45);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_10.setTransform(-44.2,-44.2,1.973,0.205,-45);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_11.setTransform(-61.9,-61.8,1.973,0.205,-45);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_12.setTransform(-79.6,-79.5,1.973,0.205,-45);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_13.setTransform(-97.2,-97.2,1.973,0.205,-45);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_14.setTransform(-114.9,-114.9,1.973,0.205,-45);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_15.setTransform(-132.6,-132.5,1.973,0.205,-45);

	this.addChild(this.shape_15,this.shape_14,this.shape_13,this.shape_12,this.shape_11,this.shape_10,this.shape_9,this.shape_8,this.shape_7,this.shape_6,this.shape_5,this.shape_4,this.shape_3,this.shape_2,this.shape_1,this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-491.4,-491.4,982.9,982.9);


(lib.TATE = function() {
	this.initialize();

	// CONCEPT (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ag/EVIAAopIB/AAIAAIpg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.9,55.5);


// stage content:
(lib.news = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// S
	this.instance = new lib.S();
	this.instance.setTransform(263.5,100,6.609,0.242,0,0,0,0.1,0);

	this.timeline.addTween(cjs.Tween.get(this.instance).to({regX:0,scaleX:1,scaleY:1,x:428.7},3,cjs.Ease.get(1)).wait(1).to({x:417.9},0).wait(5));

	// W
	this.instance_1 = new lib.W();
	this.instance_1.setTransform(321.5,100);
	this.instance_1._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(2).to({_off:false},0).wait(7));

	// E
	this.instance_2 = new lib.E();
	this.instance_2.setTransform(226.2,60,1,1.569);
	this.instance_2._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(4).to({_off:false},0).to({scaleY:1,y:100},1).wait(4));

	// right
	this.instance_3 = new lib.TATE();
	this.instance_3.setTransform(153.5,50);
	this.instance_3._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(3).to({_off:false},0).to({y:100},2,cjs.Ease.get(1)).to({_off:true},1).wait(3));

	// center
	this.instance_4 = new lib.NANAME();
	this.instance_4.setTransform(143.7,112);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(5).to({_off:false},0).to({_off:true},1).wait(3));

	// left
	this.instance_5 = new lib.TATE();
	this.instance_5.setTransform(120.2,160);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(3).to({_off:false},0).to({y:90},2,cjs.Ease.get(1)).to({_off:true},1).wait(3));

	// N
	this.instance_6 = new lib.N();
	this.instance_6.setTransform(136.9,100);
	this.instance_6._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(6).to({_off:false},0).wait(3));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(383.1,193.1,289.8,13.8);

})(lib = lib||{}, images = images||{}, createjs = createjs||{});
var lib, images, createjs;
;(function (lib, img, cjs) {

var p; // shortcut to reference prototypes

// library properties:
lib.properties = {
	width: 760,
	height: 200,
	fps: 15,
	color: "#FFFFFF",
	manifest: []
};



// symbols:



(lib.U = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AikDkQhAgzAAhfIAAlqICBAAIAAFqQAAAzAbAYQAaAZAvgBQAvAAAagYQAagZAAgyIAAlqICBAAIAAFqQAABfg/AzQhAA1hlAAQhmAAg/g1g");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22.9,-28.1,45.8,56.3);


(lib.T = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("Ag/EVIAAnGIiSAAIAAhjIGjAAIAABjIiSAAIAAHGg");
	this.shape.setTransform(0,-1);

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21,-28.7,42.1,55.5);


(lib.R = function() {
	this.initialize();

	// RECRUIT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("ABQEVIhgjFIhJAAIAADFIiFAAIAAopIDaAAQBfAAA6AsQA3ArAABQQAAA6gYAnQgWAkgzAXIB0DgIAAAGgAhZgUIBVAAQAkAAATgUQAUgUgBgkQAAgkgTgVQgTgUgkAAIhVAAg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22.3,-27.7,44.7,55.5);


(lib.I = function() {
	this.initialize();

	// BUSINESS
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("Ag/EVIAAopIB+AAIAAIpg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.8,55.5);


(lib.E = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AjAEVIAAopIGBAAIAABjIkAAAIAAB4IDYAAIAABhIjYAAIAACJID/AAIAABkg");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-19.3,-27.7,38.6,55.5);


(lib.C = function() {
	this.initialize();

	// CONCEPT
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("AidDeQhBg/AAhlIAAhyQAAhkA+hAQA/hABhAAQBoAAA4A1QA4A2AHBhIgBACIh9AAQgDg2gVgaQgWgbgzAAQgqAAgaAkQgZAjAAA5IAABzQAAA5AcAkQAbAjAuAAQAuAAAUgZQATgYADg3IB9AAIABADQgDBhg3A0Qg2AzhmAAQhlAAhAg/g");

	this.addChild(this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-22.3,-28.5,44.7,57.1);


(lib.stripe = function() {
	this.initialize();

	// レイヤー 1
	this.shape = new cjs.Shape();
	this.shape.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape.setTransform(132.6,132.6,1.973,0.205,-45);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_1.setTransform(114.9,114.9,1.973,0.205,-45);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_2.setTransform(97.2,97.3,1.973,0.205,-45);

	this.shape_3 = new cjs.Shape();
	this.shape_3.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_3.setTransform(79.5,79.6,1.973,0.205,-45);

	this.shape_4 = new cjs.Shape();
	this.shape_4.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_4.setTransform(61.9,61.9,1.973,0.205,-45);

	this.shape_5 = new cjs.Shape();
	this.shape_5.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_5.setTransform(44.2,44.2,1.973,0.205,-45);

	this.shape_6 = new cjs.Shape();
	this.shape_6.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_6.setTransform(26.5,26.6,1.973,0.205,-45);

	this.shape_7 = new cjs.Shape();
	this.shape_7.graphics.f("#000000").s().p("Eg4DA2aMBudhudIBqBqMhudBudg");
	this.shape_7.setTransform(8.8,8.9);

	this.shape_8 = new cjs.Shape();
	this.shape_8.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_8.setTransform(-8.9,-8.8,1.973,0.205,-45);

	this.shape_9 = new cjs.Shape();
	this.shape_9.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_9.setTransform(-26.5,-26.5,1.973,0.205,-45);

	this.shape_10 = new cjs.Shape();
	this.shape_10.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_10.setTransform(-44.2,-44.2,1.973,0.205,-45);

	this.shape_11 = new cjs.Shape();
	this.shape_11.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_11.setTransform(-61.9,-61.8,1.973,0.205,-45);

	this.shape_12 = new cjs.Shape();
	this.shape_12.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_12.setTransform(-79.6,-79.5,1.973,0.205,-45);

	this.shape_13 = new cjs.Shape();
	this.shape_13.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_13.setTransform(-97.2,-97.2,1.973,0.205,-45);

	this.shape_14 = new cjs.Shape();
	this.shape_14.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_14.setTransform(-114.9,-114.9,1.973,0.205,-45);

	this.shape_15 = new cjs.Shape();
	this.shape_15.graphics.f("#000000").s().p("EgnlAFtIAArYMBPLAAAIAALYg");
	this.shape_15.setTransform(-132.6,-132.5,1.973,0.205,-45);

	this.addChild(this.shape_15,this.shape_14,this.shape_13,this.shape_12,this.shape_11,this.shape_10,this.shape_9,this.shape_8,this.shape_7,this.shape_6,this.shape_5,this.shape_4,this.shape_3,this.shape_2,this.shape_1,this.shape);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-491.4,-491.4,982.9,982.9);


(lib.yokostripe01 = function() {
	this.initialize();

	// T (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("AjRAxIAAhhIGjAAIAABhg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-21,-4.9,42.1,9.9);


(lib.tatestripe02 = function() {
	this.initialize();

	// T (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ag/EVIAAopIB/AAIAAIpg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-27.7,12.9,55.5);


(lib.tatestripe01 = function() {
	this.initialize();

	// T (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.p("Ag/JLIAAyVIB/AAIAASVg");

	// レイヤー 2
	this.instance = new lib.stripe();
	this.instance.setTransform(0,0,0.4,0.4);

	this.instance.mask = mask;

	this.addChild(this.instance);
}).prototype = p = new cjs.Container();
p.nominalBounds = new cjs.Rectangle(-6.4,-58.7,12.9,117.4);


// stage content:
(lib.recruit = function(mode,startPosition,loop) {
if (loop == null) { loop = false; }	this.initialize(mode,startPosition,loop,{});

	// T
	this.instance = new lib.yokostripe01();
	this.instance.setTransform(719.4,77.2,1,1,0,0,0,-0.1,0);
	this.instance._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(4).to({_off:false},0).to({x:621.4},2,cjs.Ease.get(1)).to({_off:true},1).wait(3));

	// T
	this.instance_1 = new lib.tatestripe01();
	this.instance_1.setTransform(631.6,92);

	this.instance_2 = new lib.tatestripe02();
	this.instance_2.setTransform(631.7,100);

	this.instance_3 = new lib.T();
	this.instance_3.setTransform(631.7,101);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[]}).to({state:[{t:this.instance_1}]},3).to({state:[{t:this.instance_2}]},1).to({state:[{t:this.instance_3}]},3).wait(3));

	// I
	this.instance_4 = new lib.I();
	this.instance_4.setTransform(560.4,140.1);
	this.instance_4._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(3).to({_off:false},0).wait(1).to({y:100.1},0).wait(6));

	// U
	this.instance_5 = new lib.U();
	this.instance_5.setTransform(484.1,100.5);
	this.instance_5._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(2).to({_off:false},0).wait(8));

	// R
	this.instance_6 = new lib.R();
	this.instance_6.setTransform(395.8,100.1,14.037,0.092);

	this.timeline.addTween(cjs.Tween.get(this.instance_6).to({scaleX:1,scaleY:1},3,cjs.Ease.get(1)).wait(7));

	// C
	this.instance_7 = new lib.C();
	this.instance_7.setTransform(306,100.1);
	this.instance_7._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(2).to({_off:false},0).wait(8));

	// E
	this.instance_8 = new lib.E();
	this.instance_8.setTransform(222.5,50.1);
	this.instance_8._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(4).to({_off:false},0).wait(1).to({y:100.1},0).wait(5));

	// R
	this.instance_9 = new lib.R();
	this.instance_9.setTransform(136.6,120.3,0.476,1.317,0,0,0,0,0.1);
	this.instance_9._off = true;

	this.timeline.addTween(cjs.Tween.get(this.instance_9).wait(3).to({_off:false},0).to({regY:0,scaleX:1,scaleY:1,y:92.1},2,cjs.Ease.get(1)).wait(1).to({y:100.1},0).wait(4));

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(462,197.5,627.5,5.1);

})(lib = lib||{}, images = images||{}, createjs = createjs||{});
var lib, images, createjs;
TB.about = {

  setParallax : function(){
    // "onEnter" => 1, "onCenter" => 0.5, "onLeave" => 0
    var id = 'about';
    // カレント表示
    new ScrollMagic.Scene({triggerElement: "#" + id, triggerHook: .5, duration: "100%"})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state != TB[id].STATE){
          if(e.state == 'DURING') TB.setCurrentMenu(id);
          TB[id].STATE = e.state;
        }
      });
    // タイトルアニメーション
    new ScrollMagic.Scene({triggerElement: "#" + id, triggerHook: .5, reverse: false})
      .addTo(TB.PARALLAX)
      .on('enter', function(e){
        TB.initTitle(id);
      });

    // 白文字タイトル展開
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, offset: '300px', reverse: false})
      .setClassToggle('#' + id + ' .section-name', 'animate')
      .addTo(TB.PARALLAX);

    //テキスト表示
    new ScrollMagic.Scene({triggerElement: "#" + id, triggerHook: .3, reverse: false})
      .setClassToggle('#' + id + ' .section-main', 'animate')
      .addTo(TB.PARALLAX);
  }
}

TB.business = {

  STATE : null,

  setParallax : function(){
    var id = 'business';
    // 'onEnter' => 1, 'onCenter' => 0.5, 'onLeave' => 0
    // カレント表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, duration: '100%'})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state != TB[id].STATE){
          if(e.state == 'DURING'){
            TB.setCurrentMenu(id);
          }
          TB[id].STATE = e.state;
        }
      });
    // base
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: "100%"})
      .setTween('#' + id + ' .section-bg', {y: '-200px'})
      .addTo(TB.PARALLAX);
    // triangle
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: "100%"})
      .setTween('#' + id + " .section-triangle", {y: "-50px"})
      .addTo(TB.PARALLAX);

    // タイトルアニメーション
    new ScrollMagic.Scene({triggerElement: '#' + id + ' h2', triggerHook: 1, offset: '400px', reverse: false})
      .addTo(TB.PARALLAX)
      .on('enter', function(e){
        TB.initTitle(id);
      });

    // 白文字タイトル展開
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, offset: '300px', reverse:false})
      .setClassToggle('#' + id + ' .section-name', 'animate')
      .addTo(TB.PARALLAX);

    // テキスト表示
    new ScrollMagic.Scene({triggerElement: '#' + id + ' .labels', triggerHook: .6, reverse: false})
      .setClassToggle('#' + id + ' .labels', 'animate')
      .addTo(TB.PARALLAX);
  }
}

TB.contact = {

  setParallax : function(){
    // 'onEnter' => 1, 'onCenter' => 0.5, 'onLeave' => 0
    var id = 'contact';
    // カレント表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, duration: '100%'})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state != TB[id].STATE){
          if(e.state == 'DURING') TB.setCurrentMenu(id);
          TB[id].STATE = e.state;
        }
      });

    // base
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: "100%"})
      .setTween('#' + id + ' .section-bg', {y: '-200px'})
      .addTo(TB.PARALLAX);
    // triangle
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: "100%"})
      .setTween('#' + id + " .section-triangle", {y: "-50px"})
      .addTo(TB.PARALLAX);

    // タイトルアニメーション
    new ScrollMagic.Scene({triggerElement: '#' + id + ' h2', triggerHook: 1, offset: '500px', reverse: false})
      .addTo(TB.PARALLAX)
      .on('enter', function(e){
        TB.initTitle(id);
      });

    // 白文字タイトル展開
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, offset: '550px', reverse:false})
      .setClassToggle('#' + id + ' .section-name', 'animate')
      .addTo(TB.PARALLAX);

    //テキスト表示
    new ScrollMagic.Scene({triggerElement: "#" + id + ' h2', triggerHook: 1, offset: '500px', reverse: false})
      .setClassToggle('#' + id + ' .section-main', 'animate')
      .addTo(TB.PARALLAX);
  }
}

TB.cover = {

  movie : null,

  init : function(){
    TB.cover.resize();
  },

  setParallax : function(){
    var id = 'cover';
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 0, duration: '100%'})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state != TB[id].STATE){
          if(e.state == 'DURING'){
            if(TB.cover.movie && !$('body').is('.is_loading')){
              TB.cover.movie.play('TB.cover.setParallax');
            }
          } else {
            if(TB.cover.movie) TB.cover.movie.stop();
          }
          TB[id].STATE = e.state;
        }
      });
  },

  resize : function(){
    var $win = $(window),
        imgW = 1280,
        imgH = 720,
        winW = ($win.width() < 1280 && TB.LAYOUT == 'pc' && TB.DEVICE == 'pc') ? 1280 : $win.width(),
        scaleW = winW / imgW,
        scaleH = $win.height() / imgH,
        fixScale = Math.max(scaleW, scaleH);
    if(TB.DEVICE == 'tablet') return false;
    $('#movie').css({
        'width': imgW * fixScale,
        'height': imgH * fixScale,
        'left' : Math.floor((winW - (imgW * fixScale)) / 2),
        'top' : Math.floor(($win.height() - imgH * fixScale) / 2)
    });
  },

  loadMovie : function(callback){
    TB.cover.movie = new TB.Movie('#cover', function(){
      if(callback) callback();
    });
  }
}

TB.mission = {

  setParallax : function(){
    // 'onEnter' => 1, 'onCenter' => 0.5, 'onLeave' => 0
    var id = 'mission';
    // カレント表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, duration: '100%'})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state != TB[id].STATE){
          if(e.state == 'DURING') TB.setCurrentMenu(id);
          if(e.state == 'BEFORE') $('#menu-content a').removeClass('active');
          TB[id].STATE = e.state;
        }
      });
    // 非表示
    new ScrollMagic.Scene({triggerElement: '#news', triggerHook: 0, offset: "100%"})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state == 'BEFORE'){
          $('#' + id).removeClass('hidden');
        } else {
          $('#' + id).addClass('hidden');
        }
      });
    // タイトルアニメーション
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .1, reverse: false})
      .addTo(TB.PARALLAX)
      .on('enter', function(e){
        TB.initTitle(id);
      })

    // 白文字タイトル展開
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, offset: '150px', reverse: false})
      .setClassToggle('#' + id + ' .section-name', 'animate')
      .addTo(TB.PARALLAX);

    // テキスト表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, reverse: false})
      .setClassToggle('#' + id + ' .mission-sections', 'animate')
      .addTo(TB.PARALLAX);
  }
}

TB.news = {

  setParallax : function(){
    // 'onEnter' => 1, 'onCenter' => 0.5, 'onLeave' => 0
    var id = 'news';
    // カレント表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, duration: '100%'})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state != TB[id].STATE){
          if(e.state == 'DURING'){
            TB.setCurrentMenu(id);
          }
          TB[id].STATE = e.state;
        }
      });

    // base
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: "100%"})
      .setTween('#' + id + ' .section-bg', {y: '-200px'})
      .addTo(TB.PARALLAX);
    // triangle
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: "100%"})
      .setTween('#' + id + " .section-triangle", {y: "-50px"})
      .addTo(TB.PARALLAX);

    // タイトルアニメーション
    new ScrollMagic.Scene({triggerElement: '#' + id + ' h2', triggerHook: 1, offset: '400px', reverse: false})
      .addTo(TB.PARALLAX)
      .on('enter', function(e){
        TB.initTitle(id);
      });


    // 白文字タイトル透明度
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, offset: '200px', duration: '100px'})
      .setTween('#' + id + ' .section-name', {opacity: 1})
      .addTo(TB.PARALLAX);
    // 白文字タイトル展開
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, offset: '300px', reverse: false})
      .setClassToggle('#' + id + ' .section-name', 'animate')
      .addTo(TB.PARALLAX);
    new ScrollMagic.Scene({triggerElement: '#business', triggerHook: 1})
      .setClassToggle('#' + id + ' .section-name', 'move')
      .addTo(TB.PARALLAX);
    // テキスト表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, offset: '250px', reverse: false})
      .setClassToggle('#' + id + ' .news-entries', 'animate')
      .addTo(TB.PARALLAX);
  }
}

TB.recruit = {

  init : function(){
    TB.recruit.setEvent();
  },

  setParallax : function(){
    // 'onEnter' => 1, 'onCenter' => 0.5, 'onLeave' => 0
    var id = 'recruit';
    // カレント表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .5, duration: '100%'})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state != TB[id].STATE){
          if(e.state == 'DURING') TB.setCurrentMenu(id);
          TB[id].STATE = e.state;
        }
      });

    // 非表示
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: "250%"})
      .addTo(TB.PARALLAX)
      .on('progress', function(e){
        if(e.state == 'DURING'){
          $('#' + id).removeClass('hidden');
        } else {
          $('#' + id).addClass('hidden');
        }
      });
    // 背景ずらし
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: '250%'})
      .setTween('#' + id + ' .section-bg-inner', {y: '-60px'})
      .addTo(TB.PARALLAX);
    // 背景ずらし：マスク
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: 1, duration: '250%'})
      .setTween('#' + id + ' .section-bg-mask', {y: '30px'})
      .addTo(TB.PARALLAX);
    // アニメーション開始
    new ScrollMagic.Scene({triggerElement: '#' + id, triggerHook: .1, reverse: false})
      .setClassToggle('#' + id, 'animate')
      .addTo(TB.PARALLAX);

  },

  setEvent : function(){
    $('#recruit .section-bg-grad').on(TB.EVENTS.ANIMATION_END, function(){
      TB.initTitle('recruit');
    });
  }
}
